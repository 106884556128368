import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Box } from '@mui/material';
import { SnackbarProvider } from 'notistack';

//components
import { Signup } from './components/authentication/sign-up';
import { Signin } from './components/authentication/sign-in';
import { Logout } from './components/authentication/logout';
import { RestorePassword } from './components/authentication/restore-password';
import { PhonePasswordReset } from './components/authentication/phone-password-reset';
import { RestorePasswordSuccess } from './components/authentication/restore-password-success';
import { SignupSuccess } from './components/authentication/signup-success';
import UpdatePasswordFromLink from './components/authentication/update-password-from-link';
import { LogoLayout } from './components/logo-layout';
import EmailConfirmationFromLink from './components/settings/email-confirmation-from-link';
import { RedirectToSigninLayout } from './components/redirect-to-signin-layout';

document.documentElement.lang='ru';
document.documentElement.translate=false;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //<React.StrictMode>
    <SnackbarProvider 
      autoHideDuration={3000}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
    >
      <Box>
        <BrowserRouter>
          <Routes>
            <Route element={<LogoLayout/>}>
              <Route path='signin' element={ <Signin /> } />
              <Route path='restore-password' element= { <RestorePassword /> } />
              <Route path='phone-password-reset' element= { <PhonePasswordReset /> } />
              <Route path='signup' element={ <Signup /> } />
              <Route path='email-password-reset/:userId' element= { <UpdatePasswordFromLink /> } />
            </Route>
            <Route element={ <RedirectToSigninLayout /> }>
              <Route path='restore-password-success' element= { <RestorePasswordSuccess /> } />
              <Route path='signup-success' element= { <SignupSuccess /> } />
              <Route path='email-confirm/:userId' element= { <EmailConfirmationFromLink /> } />
            </Route>
            <Route path='logout' element={ <Logout />} />
          </Routes>
        </BrowserRouter>
      </Box>
    </SnackbarProvider>
  //</React.StrictMode>
);

