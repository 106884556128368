import React, { useState } from "react"
import { Modal, Button, Box, TextField, Grid, Typography, Backdrop, CircularProgress } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

export const PhoneTokenInputModal = (props) => {
    const [phoneToken, setPhoneToken] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);

    return (
        <React.Fragment>
                <Backdrop
                    sx={{ zIndex: 10000 }}
                    open={isDisabled}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            <Modal
                open={props.open}
                //onClose={onClose}
            >
                <form 
                    onSubmit={(event) => {
                        event.preventDefault();
                        setIsDisabled(true);
                        props.inputToken(phoneToken)
                            .finally(() => {
                                setIsDisabled(false);
                            });
                    }}>
                    <Box sx={{...style}}>
                        <h2>На ваш номер телефона отправлена СМС с кодом</h2>
                        <Grid container sx={{mt: 2, mb: 2}}>
                            <Grid container item xs={12}>
                                <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography>Введите код полученный из смс для подтверждения действия:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField 
                                        size="small"
                                        name="phoneVerificationToken"
                                        onChange={(event) => setPhoneToken(event.target.value) }
                                        required
                                    >
                                    </TextField>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} justifyContent="center" >
                                <Button
                                    onClick={() => props.resendToken()}
                                >Повторно отправить код</Button>
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            disabled={isDisabled}
                        >
                            Отправить
                        </Button>
                        <Button
                            onClick={() => props.onClose()}
                            color="error"
                        >
                            Отмена
                        </Button>
                    </Box>
                </form>
            </Modal>
        </React.Fragment>
    )
}