import React, { useState } from 'react'
import { Typography, Button, Grid, TextField, Link, FormHelperText, FormGroup, InputAdornment, Tooltip, IconButton, Backdrop, CircularProgress } from '@mui/material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import AuthService from '../../services/authService'; 
import { Formik, Field, Form } from 'formik';
import * as Yup from "yup";
import { PhoneEmailValidatedInput } from '../phone-email-validated-input';
import { useSearchParams } from 'react-router-dom';

import { phone_or_email_validation_rule } from '../../constants/validation_rules';

export const Signin = () => {
    const [errors, setErrors] = useState([]);
    const [searchParams] = useSearchParams();

    const handleAuthorization = async (formValues) => {
        setErrors([]);
        const model = {
            'username': formValues.login,
            'password': formValues.password,
            'returnUrl': searchParams.get('ReturnUrl')
        }
        return AuthService.signin(model)
            .then((response) => {
                if (response !== true) {
                    setErrors([ 'Не удалось авторизоваться по данному логину и паролю' ]);
                }
            })
            .catch((error) => {
                setErrors([ 'Произошла ошибка при авторизации' ]);
            })
    }

    const validationSchema = Yup.object({
        login: phone_or_email_validation_rule
            .required('Обязательное поле'),
        password: Yup.string()
            .required('Обязательное поле'),
    });

    return (
        <React.Fragment>
            <Typography component="h1" variant="h5">
                Авторизация
            </Typography>
            <Formik 
                initialValues={{login: '', password: '', rememberMe: false}}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    handleAuthorization(values)
                        .finally(() => {
                            setSubmitting(false);
                        })
                }}
            >
            { formik => (
                <Form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
                    <Backdrop
                        sx={{ zIndex: 10000 }}
                        open={formik.isSubmitting}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Field name="login">
                        {({field, form, meta}) => (
                            <FormGroup>
                                <PhoneEmailValidatedInput 
                                    id="login"
                                    name="login"
                                    formik={formik} 
                                    field={field} 
                                    meta={meta} 
                                    fieldLabel="Логин" 
                                    fieldName="login"
                                    autoComplete="current-login"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment 
                                                position="start"
                                            >
                                                <Tooltip
                                                    enterTouchDelay={0}
                                                    title={
                                                    <div style={{ whiteSpace: 'pre-line' }}>
                                                        {"В качестве логина используется номер телефона либо подтвержденный e-mail"}
                                                    </div>
                                                    }>
                                                    <IconButton size="small" tabIndex={-1}>
                                                    <HelpOutlineIcon fontSize="inherit" />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                        }}    
                                />
                            </FormGroup>
                        )}
                    </Field>
                    <Field name="password">
                        {({field, form, meta}) => (
                            <FormGroup>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    name="password"
                                    label="Пароль"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    helperText={meta.touched && meta.error}
                                    error={ meta.touched && meta.error != null }
                                    {...formik.getFieldProps('password')}
                                />
                            </FormGroup>
                        )}
                    </Field>

                    {/* server errors */}
                    { errors.map((error, key) => <FormHelperText error key={key}>{error}</FormHelperText>) }

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={formik.isSubmitting}
                    >
                        Войти
                    </Button>
                </Form>
            )}
            </Formik>
            <Grid container>
                <Grid item xs>
                    <Link href="/restore-password" variant="body2">
                        Забыли пароль?
                    </Link>
                </Grid>
                <Grid item>
                    <Link href={`/signup?${searchParams}`} variant="body2">
                        Регистрация
                    </Link>
                </Grid>
            </Grid>
		</React.Fragment>
    )
}
