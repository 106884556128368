import React, { useState } from 'react'
import { Typography, Box, Button, Container, TextField, FormGroup, FormHelperText } from '@mui/material'

import { PhoneTokenInputModal } from './phone_token_input_modal';
import AuthService from '../../services/authService'; 
import { Formik, Field, Form } from 'formik';
import { useSearchParams, useNavigate } from 'react-router-dom';
import * as Yup from "yup";
import { useSnackbar } from "notistack";

import { password_validation_rule } from '../../constants/validation_rules';
import { resetPasswordTypes } from '../../constants/reset_password_types';
import { phoneTokenPurposeTypes } from '../../constants/phone_token_purpose_types';

export const PhonePasswordReset = () => {
    const navigate = useNavigate();
    const [errors, setErrors] = useState([]);
    const [params] = useSearchParams();
    const [ showPhoneTokenInputModal, setShowPhoneTokenInputModal] = useState(false);
    const [ inputValues, setInputValues ] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    
    const phoneNumber = params.get('phoneNumber');

    const handlePasswordReset = async (formValues) => {
        setErrors([]);

        const request = {
            UserIdOrPhone: formValues.phone,
            Password: formValues.password,
            Token: formValues.token,
            Type: resetPasswordTypes.ByPhone
        }

        return AuthService.resetPassword(request)
            .then((result) => {
                if (result.isOk !== true) {
                    setErrors([result.message]);
                } else {
                    navigate('/restore-password-success');
                }
            })
            .catch(() => {
                setErrors(["Произошла ошибка при попытке сменить пароль"]);
            });
    }

    const validationSchema = Yup.object({
        password: password_validation_rule
            .required('Обязательное поле'),
        confirm_password: password_validation_rule
            .oneOf([Yup.ref('password')], 'Введенные пароли не совпадают')
            .required('Обязательное поле'),
    });

    const handleInputToken = (token) => {
        const request = {...inputValues, token};
        handlePasswordReset(request)
            .then(() => {
                setShowPhoneTokenInputModal(false);
            })
    }

    const handleSendToken = () => {
        return AuthService.sendPhoneConfirmation(phoneNumber, phoneTokenPurposeTypes.forPasswordReset)
            .then(async (result) => {
                if (result.status === 429) {
                    setShowPhoneTokenInputModal(true);
                    enqueueSnackbar(`Не удалось отправить смс, на этот номер уже недавно было отправлено сообщение`, { variant: "error" });
                    return;
                }
                var json = await result.json();
                if (json.isOk !== true) {
                    enqueueSnackbar(`Произошла ошибка при отправке смс, ${json.message}`, { variant: "error" });
                } else {
                    setShowPhoneTokenInputModal(true);
                    enqueueSnackbar('Успешно отправлено смс с кодом', { variant: "success" });
                }
            })
            .catch(() => {
                enqueueSnackbar(`Произошла ошибка при отправке смс`, { variant: "error" });
            });
    }

    const handleModalClose = () => {
        setShowPhoneTokenInputModal(false);
    }

    return (
        <React.Fragment>
            <PhoneTokenInputModal open={showPhoneTokenInputModal} inputToken={handleInputToken} resendToken={handleSendToken} onClose={handleModalClose} />
                <Typography component="h1" variant="h5">
                    Восстановление пароля
                </Typography>
                <Formik 
                    initialValues={{phone: phoneNumber, password: '', confirm_password: ''}}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        setInputValues(values);
                        handleSendToken()
                            .then(() => {
                                setSubmitting(false);
                            })                            
                    }}
                >
                { formik => (
                    <Form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
                        <Field name="password">
                            {({field, form, meta}) => (
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    name="password"
                                    label="Новый пароль"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    helperText={meta.touched && meta.error}
                                    error={ meta.touched && meta.error != null }
                                    {...formik.getFieldProps('password')}
                                />
                            )}
                        </Field>
                        <Field name="confirm_password" >
                            {({field, form, meta}) => (
                                <FormGroup>
                                    <TextField
                                        margin="normal"
                                        fullWidth
                                        type="password"
                                        id="confirm_password"
                                        label="Подтверждение нового пароля"
                                        name="confirm_password"
                                        {...formik.getFieldProps('confirm_password')}
                                        error={meta.touched  && meta.error != null}
                                        helperText={meta.touched && meta.error}
                                    />
                                </FormGroup>
                            )}
                        </Field>

                        {/* server errors */}
                        { errors.map((error, key) => <FormHelperText error key={key}>{error}</FormHelperText>) }

                        <Button
                            type="submit"
                            disabled={formik.isSubmitting}
                            fullWidth
                            variant="contained"
                            sx={{ mt: 2 }}
                        >
                            Сменить пароль
                        </Button>

                        <Button
                            type="button"
                            variant="text"
                            fullWidth
                            sx={{ mt: 1}}
                            onClick={() => navigate(-1)}
                        >
                            Вернуться
                        </Button>
                    </Form>
                )}
                </Formik>
		</React.Fragment>
    )
}
