import React, { useEffect } from 'react'
import { Outlet } from "react-router-dom";
import { Container, Box, Button, Typography } from "@mui/material";
import { useSearchParams, useNavigate } from 'react-router-dom';

export const RedirectToSigninLayout = () => {
	const [searchParams] = useSearchParams();
    const navigate = useNavigate();

	const returnUrl = searchParams.get('ReturnUrl');

	useEffect(() => {
		if (returnUrl) {
			//redirect to login page after 12 sec
			setTimeout(() => {
				navigate({
					pathname: '/signin',
					search: `?${searchParams}`
				});
			}, 12000);
		}
	}, [])

	if (returnUrl){
		return (
			<React.Fragment>
				<Container maxWidth="md">
					<Box
						sx={{
							marginTop: 8,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
							border: '1px'
						}}
					>
						<Outlet/>
						<Typography>
							(<a href={`/signin?${searchParams}`}>перенаправление</a> на страницу авторизации через несколько секунд)
						</Typography>
					</Box>
				</Container>
			</React.Fragment>
		)
	} else {
		return (
			<React.Fragment>
				<Container maxWidth="md">
					<Box
						sx={{
							marginTop: 8,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
							border: '1px'
						}}
					>
						<Outlet/>

						<div style={{ marginTop: 24 }}>
							Теперь можете перейти на наши сервисы:
						</div>
						<div>
							<Button 
								variant="outlined" 
								href="https://zakupki.expertcentre.org/"
								sx={{m: 1}}
							>
								Поисковик
							</Button>
							<Button 
								variant="outlined" 
								href="https://portal.expertcentre.org/"
								sx={{m: 1}}
							>
								Личный кабинет клиента
							</Button>
						</div>
					</Box>
				</Container>
			</React.Fragment>
		)
	}
}
