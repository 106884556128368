import React, { useState, useEffect } from "react"
import { Typography, Container, Box, CircularProgress } from "@mui/material"
import AuthService from "../../services/authService"
import { withRouter } from '../../withRouter';
import { useSearchParams } from 'react-router-dom';
import { flexbox } from "@mui/system";

const EmailConfirmationFromLink = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);

        const request = {
            UserId : props.params.userId,
            Token : searchParams.get('token')
        };
        
        AuthService.confirmEmail(request)
        .then(() => {
            setMessage(`E-mail успешно подтвержден.`);
        })
        .catch((error) => {
            setMessage(`Произошла ошибка при подтверждении e-mail. \r\n ${error}`);
        })
        .finally(() => {
            setIsLoading(false);
        })
    }, [])

    if (isLoading) {
        return <CircularProgress />
    }
    
    return (
        <React.Fragment>
            <Container maxWidth="md">
                {
                    isLoading
                    ?
                        <CircularProgress />
                    :
                        <Typography>
                            {message}
                        </Typography>
                }
                
            </Container>
        </React.Fragment>
    )
}

export default withRouter(EmailConfirmationFromLink);
