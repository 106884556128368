import React, { useState } from "react"
import { Typography, Box, Button, Container, TextField, FormGroup, FormHelperText } from '@mui/material'
import authService from "../../services/authService"
import { withRouter } from '../../withRouter';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import * as Yup from "yup";

import { password_validation_rule } from '../../constants/validation_rules';
import { resetPasswordTypes } from "../../constants/reset_password_types";

const UpdatePasswordFromLink = (props) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [errors, setErrors] = useState([]);

    const handlePasswordReset = async (formValues) => {
        setErrors([]);

        const request = {
            UserIdOrPhone: props.params.userId,
            Password: formValues.password,
            Token: searchParams.get('token'),
            Type: resetPasswordTypes.ByEmail
        }

        return authService.resetPassword(request)
            .then((result) => {
                if (result.isOk !== true) {
                    setErrors([result.message]);
                } else {
                    navigate('/restore-password-success');
                }
            })
            .catch(() => {
                setErrors(["Произошла ошибка при попытке сменить пароль"]);
            });
    }

    const validationSchema = Yup.object({
        password: password_validation_rule
            .required('Обязательное поле'),
        confirm_password: password_validation_rule
            .oneOf([Yup.ref('password')], 'Введенные пароли не совпадают')
            .required('Обязательное поле'),
    });

    return (
        <React.Fragment>
            <Typography component="h1" variant="h5">
                Восстановление пароля
            </Typography>
            <Formik 
                initialValues={{ password: '', confirm_password: ''}}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    handlePasswordReset(values);
                    setSubmitting(false);
                }}
            >
            { formik => (
                <Form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
                    <Field name="password">
                        {({field, form, meta}) => (
                            <TextField
                                margin="normal"
                                fullWidth
                                name="password"
                                label="Новый пароль"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                helperText={meta.touched && meta.error}
                                error={ meta.touched && meta.error != null }
                                {...formik.getFieldProps('password')}
                            />
                        )}
                    </Field>
                    <Field name="confirm_password" >
                        {({field, form, meta}) => (
                            <FormGroup>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    type="password"
                                    id="confirm_password"
                                    label="Подтверждение нового пароля"
                                    name="confirm_password"
                                    {...formik.getFieldProps('confirm_password')}
                                    error={meta.touched  && meta.error != null}
                                    helperText={meta.touched && meta.error}
                                />
                            </FormGroup>
                        )}
                    </Field>

                    {/* server errors */}
                    { errors.map((error, key) => <FormHelperText error key={key}>{error}</FormHelperText>) }

                    <Button
                        type="submit"
                        disabled={formik.isSubmitting}
                        fullWidth
                        variant="contained"
                        sx={{ mt: 1, mb: 2 }}
                    >
                        Сменить пароль
                    </Button>
                </Form>
            )}
            </Formik>
		</React.Fragment>
    )
}

export default withRouter(UpdatePasswordFromLink);
