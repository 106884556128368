import React from 'react'
import { Outlet } from "react-router-dom";
import { Box, Container } from '@mui/material'

export const LogoLayout = () => {
    return (
        <React.Fragment>
            <Container maxWidth="xs">
				<Box
					sx={{
						marginTop: 8,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
                    <img src="/logo.png" alt="logo" style={{marginBottom: 24}}/>
                    <Outlet />
			    </Box>
            </Container>
		</React.Fragment>
    )
}
