import React, { useState } from 'react'
import { TextField } from '@mui/material'
import InputMask from 'react-input-mask'

import { phoneValidationRegex } from '../constants/validation_rules';

const standartifyPhoneNumber = (phoneNumber) => {
    if (phoneNumber[0] === '8' || phoneNumber[0] === '7') {
        phoneNumber='+7' + phoneNumber.substring(1);
    }

    if (phoneNumber.indexOf('_') > -1){
        return phoneNumber;
    }

    return phoneNumber
        .replaceAll(/[^+\d]/g, "")
}

export const PhoneEmailValidatedInput = (props) => {
    //show masked phone input instead of text
    //const [phoneInputEnabled, setPhoneInputEnabled] = useState(false);
    //if cancelled don't show phone input until input refocused
    const [phoneInputCancelled, setPhoneInputCancelled] = useState(false);

    const defaultPhoneInput = "+7 ";
    const phoneInputMask = defaultPhoneInput + "999 999 9999";

    return (
        <React.Fragment>
            <InputMask
                {...props.field}
                mask={phoneInputCancelled ? '' : phoneInputMask}
                maskPlaceholder={null}
                onChange={(event) => {
                    const value = event.target.value;

                    if (phoneInputCancelled) {
                        if (value) {
                            const standartifiedPhoneNumber = standartifyPhoneNumber(value);
                            if (standartifiedPhoneNumber.match(phoneValidationRegex) && phoneInputCancelled !== true) {
                                props.formik.setFieldValue(props.fieldName, standartifiedPhoneNumber);
                            } else {
                                props.formik.setFieldValue(props.fieldName, value);
                            }
                        } else {
                            props.formik.setFieldValue(props.fieldName, '');
                        }
                    } else {
                        if (value){
                            props.formik.setFieldValue(props.fieldName, standartifyPhoneNumber(value));
                        }
                        else {
                            props.formik.setFieldValue(props.fieldName, '');
                        }
                    }
                }}
                onKeyDown={(event) => {
                    if (event.target.value === defaultPhoneInput && event.key === 'Backspace') {
                        props.formik.setFieldValue(props.fieldName, '');
                        setPhoneInputCancelled(true);
                    }
                }}
                autoFocus
                onBlur={(event) => {
                    if (!event.target.value) {
                        setPhoneInputCancelled(false);
                    }
                }}
                beforeMaskedStateChange={({ nextState, currentState, previousState }) => {
                    if (nextState.enteredString) {
                        var standartifiedPhoneNumber = standartifyPhoneNumber(nextState.enteredString);
                        if (standartifiedPhoneNumber.match(phoneValidationRegex)) {
                            
                            const result = {
                                ...nextState,
                                value: standartifiedPhoneNumber
                            };
                            return result; 
                        }
                    }

                    //if pasted full phone number set cursor to the end of string
                    if (standartifyPhoneNumber(nextState.value).match(phoneValidationRegex)){
                        nextState.selection = {
                            start: 15,
                            end: 15,
                            length: 0
                        }
                    }

                    return { ...nextState}; 
                }}
            >
                <TextField
                    margin="normal"
                    fullWidth
                    id="login_phone"
                    label={props.fieldLabel}
                    name={props.fieldName}
                    autoComplete={props.fieldName}
                    helperText={props.meta.touched && props.meta.error}
                    error={ props.meta.touched && props.meta.error != null }
                    InputProps={ props.InputProps }
                />
            </InputMask>
		</React.Fragment>
    )
}
