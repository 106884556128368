import React from 'react'
import { Typography, Container, Box } from '@mui/material'

export const SignupSuccess = () => {
    return (
        <React.Fragment>
            <Typography>
                Пользователь успешно зарегистрирован.
            </Typography>
            <Typography>
                Так же на указанный e-mail выслано письмо с инструкцией по его активации.
            </Typography>
            <Typography sx={{ mb: 3 }}>
                В качестве логина используется номер телефона либо активированный e-mail.
            </Typography>
		</React.Fragment>
    )
}
