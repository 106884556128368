import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Typography, Box, Button, Container, TextField, FormGroup } from '@mui/material'

import { Formik, Field, Form } from 'formik';
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import authService from '../../services/authService';
import { PhoneEmailValidatedInput } from '../phone-email-validated-input';

import { phone_or_email_validation_rule, phoneValidationRegex, emailValidationRegex } from '../../constants/validation_rules';

export const RestorePassword = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const handleSendEmail = (email) => {
        authService.sendResetPasswordEmail(email)
            .then(() => {
                enqueueSnackbar(`на указанный e-mail отправлено письмо с инструкцией по восстановлению пароля`, { variant: "success" });
            })
            .catch(() => {
                enqueueSnackbar(`Произошла ошибка при отправке e-mail`, { variant: "error" });
            });
    }

    const validationSchema = Yup.object({
        login: phone_or_email_validation_rule
            .required('Обязательное поле')
    });

    return (
        <React.Fragment>
            <Typography component="h1" variant="h5">
                Восстановление пароля
            </Typography>
            <Formik 
                initialValues={{login: ''}}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    let isValidPhone = phoneValidationRegex.test(values.login);
                    if (isValidPhone){
                        navigate(`/phone-password-reset?phoneNumber=${encodeURIComponent(values.login)}`);
                    };
                    
                    let isValidEmail = emailValidationRegex.test(values.login);
                    if (isValidEmail){
                        handleSendEmail(values.login);
                    }

                    setSubmitting(false);
                }}
            >
            { formik => (
                <Form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
                    <Field name="login">
                        {({field, form, meta}) => (
                            <FormGroup>
                                <PhoneEmailValidatedInput formik={formik} field={field} meta={meta} fieldName="login" fieldLabel="Логин" />
                            </FormGroup>
                        )}
                    </Field>

                    <Button
                        type="submit"
                        disabled={formik.isSubmitting}
                        fullWidth
                        variant="contained"
                        sx={{ mt: 2 }}
                    >
                        Восстановить
                    </Button>

                    <Button
                        variant="text"
                        fullWidth
                        sx={{ mt: 1}}
                        onClick={() => navigate(-1)}
                    >
                        Вернуться
                    </Button>

                </Form>
            )}
            </Formik>
		</React.Fragment>
    )
}
