import React, { Component } from 'react';
import authService from '../../services/authService';

export class Logout extends Component {

  constructor(props) {
    super(props);
	this.logout();
  }
  
  getLogoutIdFromQuery() {
	const query = new URLSearchParams(window.location.search);
	var logoutId = query.get('logoutId');
	return logoutId;
  }
  
  logout() {
		authService.logout(this.getLogoutIdFromQuery())
		.then((response) => {
			if (response.isOk) {
				console.log('Пользователь разлогинен!');
				window.location.replace(response.postLogoutRedirectUri);
			}
		},
		(error) => {
			console.log('Произошла ошибка при попытке разлогиниться'+error);
		});
  }

  render() { return (
	<div></div>
  )}
}
export default Logout; 