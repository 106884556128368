import React from 'react'
import { Typography, Container, Box } from '@mui/material'

export const RestorePasswordSuccess = () => {
    return (
        <React.Fragment>
            <Typography>
                Пароль успешно обновлен.
            </Typography>
		</React.Fragment>
    )
}
